const theme = {
  colors: {
    text: "#000000",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#0066AC",
    secondary: "#004A7D",
    light: "#ffffff",
    dark: "#000000",
    grey: "#F0EFED",
    darkGrey: "#E7E5E0",
    lightGrey: "rgba(0,0,0,.25)",
    lighten: "rgba(255,255,255,.50)",
    modes: {
      // default: {
      //   primary: "#0066AC",
      // },
      automation: {
        primary: "#D65129",
      },
    },
  },
  fonts: {
    body: '"Roboto" ,system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    montserrat:
      '"Montserrat","Roboto" ,system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      '"Roboto",system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 38, 48, 62, 74, 96, 128, 192, 256, 300, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 74],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    p: {
      fontWeight: "300",
      fontSize: [3],
      lineHeight: 1.3,
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [4, 5],
      fontFamily: "heading",
      lineHeight: "1.3",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      maxWidth: "1184px",
      padding: [3, 4],
    },
    sm: {
      maxWidth: "780px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "1344px",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
    oneSideRight: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1280px) / 2) + 1280px)",
      ],
      mr: ["auto", 0, 0, 0],
      padding: [3, 4],
      pr: [3, 0, 0, 0],
    },
  },
  forms: {
    label: {
      color: "light",
      fontFamily: "montserrat",
      fontSize: [1],
      mb: [2],
    },
  },
  inputs: {
    primary: {
      /* UI Properties */
      px: [3],
      py: [3],
      border: ["1px solid"],
      borderColor: "lightGrey",
      borderRadius: "0",
      outline: "none !important",
      "&:active,&:focus": {
        outline: "none !important",
        borderRadius: "0",
        borderColor: "primary",
        color: "dark",
      },
    },
    light: {
      /* UI Properties */
      px: [3],
      py: [3],
      border: ["1px solid"],
      borderColor: "lighten",
      borderRadius: "0px",
      color: "light",
      borderRadius: "0",
      "&:active,&:focus": {
        outline: "none !important",
        borderRadius: "0",
        borderColor: "light",
        color: "light",
      },
      "&::placeholder": {
        color: "light",
        opacity: 0.6,
      },
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#0066AC",
      "--swiper-pagination-bottom": 0,
      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
